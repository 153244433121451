const env = process.env.NODE_ENV
export let baseUrl = () => {
  switch (env) {
    case 'development':
      return 'https://tfjl.zhujitech.com/' // 开发服务器
    // return 'http://192.168.18.117:8080/' // 开发服务器
    case 'release':
      return 'https://tfjl.zhujitech.com/' // 测试服务器
    case 'production':
      return 'https://tfjl.zhujitech.com/'
  }
}

let fileUrl = () => {
  switch (env) {
    case 'development':
      // return 'http://192.168.0.22:3005'
      return 'https://file.zhujitech.com'
    case 'release':
      return 'https://file.zhujitech.com'
    case 'production':
      return 'https://file.zhujitech.com'
  }
}
export let audioUrl = fileUrl() + '/file/'
export let uploadImg = fileUrl() + '/upload'
export let showImg = fileUrl() + '/image/'
export let showFile = fileUrl() + '/file/'
