<template>
  <div id="app">
    <div style="min-width: 1200px;" v-if="!$route.meta.isH5">
      <a-config-provider :locale="zhCN">
        <a-config-provider
          :getPopupContainer="node => { if (node) { return node.parentNode; } return bodyNode }"
        >
          <router-view />
        </a-config-provider>
      </a-config-provider>
    </div>
    <div v-if="$route.meta.isH5" style=" width: 100vw;">
      <router-view />
    </div>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data () {
    return {
      zhCN,
      bodyNode: document.body
    }
  },
  created () {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      console.log(window.location.href)
      window.location.href = 'https://m.zhujitech.com' // 正式
    }
  }
}
</script>
<style>
#app {
  /* width: 100%; */
  /* height: 100%; */

  background: #f1f2f2;
  color: #555;
  overflow-x: hidden;
}
</style>
