import axios from './axios'
import md5 from 'js-md5'

export default {
  // 发送登录验证码
  async sendLoginCaptcha (params) {
    let res = await axios.post('api/account/sendLoginCaptcha', params)
    return res
  },
  // 验证码登录
  async reqLogin (params) {
    let res = await axios.post('api/account/mobilePhoneLogin', params)
    if (res.token) {
      localStorage.setItem('token', res.token)
    }
    return res
  },
  // 注册
  async reqRegister (params) {
    // const params = {
    //   companyName: paramsObj.companyName,
    //   mobilePhone: paramsObj.mobilePhone,
    //   password: md5(paramsObj.password + salt),
    //   confirmPassword: md5(paramsObj.confirmPassword + salt),
    //   captcha: paramsObj.captcha
    //   // password: md5(password + salt),
    //   // mobilePhone: mobilePhone
    // }
    let res = await axios.post('api/account/register', params)
    return res
  },
  // 获取验证码
  async sendUpdatePasswordCaptcha (params) {
    let res = await axios.post('api/account/sendUpdatePasswordCaptcha', params)
    return res
  },
  // 设置密码
  async setPassword (params) {
    let res = await axios.post('api/account/setPassword', params)
    return res
  },
  // 密码登录接口
  async passwordLogin (mobilePhone, password, salt) {
    const params = {
      password: md5(password + salt),
      mobilePhone: mobilePhone
    }
    let res = await axios.post('/api/account/passwordLogin', params)
    if (res.token) {
      localStorage.setItem('token', res.token)
      // publicJs.getUser({})
    }
    return res
  },
  // 获取盐
  async getSalt (params) {
    let res = await axios.post('api/account/getSalt', params)
    return res
  },
  // 获取修改手机号
  async updateMobile (params) {
    let res = await axios.post('api/account/updateMobile', params)
    return res
  },
  // 获取修改手机号
  async sendRegisterCaptcha (params) {
    let res = await axios.post('api/account/sendRegisterCaptcha', params)
    return res
  },
  // PV
  addOffcialWebsitePv (params) {
    return axios.post('api/pv/addOfficialWebsitePv', params)
  }
}
