<template>
  <div class="route_header">
    <div v-for="item in routes" :key="item.key" :class="item.key === selectedKey ? 'route_item_selected' : 'route_item'" @click="onRoute(item)">
      <span class="img" :title="item.title" :style="{ 'background-position': item.key === selectedKey ? item.selectIcon : item.icon }"></span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      routes: [
        {
          key: 'index',
          icon: '-100px -50px',
          selectIcon: '-25px -50px',
          title: '账号管理'
        },
        {
          key: 'authorization',
          icon: '-125px -50px',
          selectIcon: '-50px -50px',
          title: '授权管理'
        },
        {
          key: 'order',
          icon: '-75px -50px',
          selectIcon: '-0 -50px',
          title: '订单管理'
        }
      ]
    }
  },
  props: {
    selectedKey: {
      type: String,
      default () {
        return 'index'
      }
    }
  },
  methods: {
    onRoute (item) {
      if (item.key === this.selectedKey) {
        return
      }
      this.$router.push({ path: '/' + item.key })
    }
  }
}
</script>

<style lang="less" scoped>
.route_header {
  display: flex;
  flex-direction: column;
  width: 50px;

  .route_item {
    height: 60px;
    width: 50px;
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .route_item_selected {
    height: 60px;
    width: 50px;
    background: #fff;
    border-radius: 6px 0 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .img {
    background: url('../../../assets/images/sprite.png') no-repeat;
    height: 24px;
    width: 24px;
    background-position: 0 -50px;
    cursor: pointer;
  }
}
</style>
