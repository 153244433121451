import Vue from 'vue'
import Vuex from 'vuex'
import flowTask from './flowTask'
import { showImg } from '@/request/url.js'
import API from '@/request/index'
console.log(showImg)
Vue.use(Vuex)
const state = {
  integral: 0,
  isUpdataFreeNum: 0,
  XImgQRCode: localStorage.getItem('XImgQRCode'),
  XToolVipType: localStorage.getItem('XToolVipType')
}
const mutations = {
  undataIntegral (state, integral) {
    state.integral = integral
  },
  undataFreeNum (state, isUpdataFreeNum) {
    state.isUpdataFreeNum = isUpdataFreeNum
  },
  setImgQRCode (state, provider) {
    state.XImgQRCode = provider
    localStorage.setItem('XImgQRCode', provider)
  },
  setToolVipType (state, provider) {
    state.XToolVipType = provider
    localStorage.setItem('XToolVipType', provider)
  }
}
const actions = {
  AcUserIntegral ({ commit }) {
    API.flowTaskCenter.getUserIntegral({}).then(res => {
      console.log(28, 'getUserIntegral')
      commit('undataFreeNum', state.isUpdataFreeNum + 1)
      commit('undataIntegral', res.integral)
    }).catch(err => {
      console.log(err)
    })
  }
}
export default new Vuex.Store({
  modules: {
    flowTask
  },
  state,
  mutations,
  actions,
  getters: {
    GImgQR: state => {
      return showImg + state.XImgQRCode
    }
  }
})
