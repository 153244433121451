import axios from './axios'

export default {
  // 获取修改手机号
  async orderPage (params) {
    let res = await axios.post('api/order/orderPage', params)
    return res
  },
  // 商品授权列表(包括已购买未授权)
  productAuths (params) {
    return axios.post('api/auth/productAuths', params)
  },
  // 绑定授权
  bindAuth (params) {
    return axios.post('api/auth/bindAuth', params)
  },
  // 获取oauth2code
  getOAuthCode (params) {
    return axios.post('api/auth/getOAuthCode', params)
  }
}
