import account from './account'
import company from './company'
import order from './order'
import product from './product'
import auth from './auth'
const api = {
  account,
  company,
  order,
  product,
  auth
}
export default api
