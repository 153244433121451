import Vue from 'vue'
import api from '../request/index'
import {
  showImg,
  uploadImg,
  audioUrl,
  showFile,
  baseUrl
} from '../request/url'
import {
  isInvalid,
  isValid
} from '../libs/verify'
import {
  notificationErr,
  errorMeg,
  formatMoney,
  formatFrequency,
  isOverFileLimitSize,
  openUserAgreement,
  openSettlementAgreement,
  timestampToTime
} from '../libs/tools'
// import { formatTime } from '../libs/date'
// import { menuRouter } from '../libs/routerConst'
// import { errorTip } from '../libs/errorConst'
// import { textEllipsis } from '../libs/string'
// import regular from '../libs/regular'
// import { getDate, getDateBysecond, getTimeRange } from '@l/tools'
// 请求接口
Vue.prototype.$api = api
Vue.prototype.$showImg = showImg
Vue.prototype.$baseUrl = baseUrl
Vue.prototype.$showFile = showFile
Vue.prototype.$uploadImg = uploadImg
Vue.prototype.$audioUrl = audioUrl
// Vue.prototype.$errorTip = errorTip

// Vue.prototype.$menuRouter = menuRouter
Vue.prototype.$isOverFileLimitSize = isOverFileLimitSize
Vue.prototype.$openUserAgreement = openUserAgreement
Vue.prototype.$openSettlementAgreement = openSettlementAgreement
Vue.prototype.$timestampToTime = timestampToTime
// // 字符串验证
Vue.prototype.$isInvalid = isInvalid
Vue.prototype.$isValid = isValid
Vue.prototype.$notificationErr = notificationErr
Vue.prototype.$errorMeg = errorMeg
Vue.prototype.$formatMoney = formatMoney
Vue.prototype.$formatFrequency = formatFrequency
// Vue.prototype.$formatTime = formatTime
// Vue.prototype.$regular = regular
// Vue.prototype.$textEllipsis = textEllipsis
// // 时间戳转换
// Vue.prototype.$getDate = getDate
// Vue.prototype.$getDateBysecond = getDateBysecond
// // 浏览时长转换
// Vue.prototype.$getTimeRange = getTimeRange
