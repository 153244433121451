const state = {
  currentSkuName: JSON.parse(sessionStorage.getItem('vuex-currentSkuName')) || ''
}
const mutations = {
  setCurrentSkuName (state, data) {
    sessionStorage.setItem('vuex-currentSkuName', JSON.stringify(data))
    state.currentSkuName = data
  }
}
const actions = {}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
