import axios from 'axios'
import { message } from 'ant-design-vue'
import { errorTips, responseCodeError } from './errorTips.js'
import { baseUrl } from './url.js'
import router from '@/router'

// http request 拦截器
// 根据环境切换接口地址
axios.defaults.baseURL = baseUrl()
axios.defaults.timeout = 300000 // 5000

axios.interceptors.request.use(
  config => {
    if (!config.data) {
      config.data = {}
    }
    config.headers = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    }
    return config
  },
  error => Promise.reject(error)
)

let requestLock = false
// 添加响应拦截器
axios.interceptors.response.use(
  res => {
    requestLock = false
    if (res.headers['content-type'] === 'application/x-zip-compressed') {
      return res
    }
    const {
      config: { url, baseURL }
    } = res
    const api = url.replace(baseURL, '') // 拿到后端的路由
    // else if (res.data.resultStates === 1 && (res.data.failureReason === 1 || res.data.failureReason === 2)) {
    //   if (res.data.failureReason === 2) {
    //     // message.error('数据获取失败!')
    //   }
    //   return res.data.failureReason + 100
    // }
    if (res.data.resultStates === 0) {
      return res.data.content || true
    } else if (res.data.type) {
      return res || true
    } else if (errorTips[api] && errorTips[api][res.data.failureReason]) {
      // 单独接口的枚举报错
      message.error(errorTips[api][res.data.failureReason])
      return Promise.reject(res.data.failureReason)
    } else if (responseCodeError && responseCodeError[res.data.failureReason]) {
      // 单独接口的枚举报错
      message.error(responseCodeError[res.data.failureReason])
      return Promise.reject(res.data.failureReason)
    } else {
      return Promise.reject(res.data.failureReason)
    }
  },
  // eslint-disable-next-line complexity
  error => {
    let resStatus = error.response.status
    let failReason = error.response.data.failureReason
    const {
      config: { url, baseURL }
    } = error
    const api = url.replace(baseURL, '') // 拿到后端的路由
    if (errorTips[api] && errorTips[api][failReason]) {
      // 单独接口的枚举报错
      message.error(errorTips[api][failReason])
      return
    }
    if (responseCodeError && responseCodeError[failReason] && failReason !== 4011) {
      // 单独接口的枚举报错
      message.error(responseCodeError[failReason])
      return
    }
    if (
      (resStatus === 401 ||
        failReason === 4011 ||
        failReason === 4012 || failReason === 4012) && api !== 'api/user/getUserIntegral'
    ) {
      if (!requestLock) {
        message.error('登录超时,请重新登录')
        localStorage.removeItem('token')
        localStorage.removeItem('avatarId')
        localStorage.removeItem('merchant')
        localStorage.removeItem('mobilePhone')
        localStorage.removeItem('nickname')
        localStorage.removeItem('provider')
        router.push('/login')
      }
      requestLock = true
    }
    if (resStatus === 502) {
      if (!requestLock) {
        message.error('系统重启了，请稍等！')
      }
      requestLock = true
    }
    if (resStatus === 1003) {
      if (!requestLock) {
        message.error('积分不足')
      }
      requestLock = true
    }
    if (resStatus === 4003) {
      if (!requestLock) {
        message.error('请勿重复提交')
      }
      requestLock = true
    }
    if (resStatus === (500 || 400)) {
      if (!requestLock) {
        message.error('网络请求超时，请稍后重试')
      }
      requestLock = true
    }
    if (failReason === 1) {
      message.error('请检查ID或链接地址是否正确')
      requestLock = true
    }
    if (failReason === 2) {
      message.error('数据获取失败！')
      requestLock = true
    }

    if (!requestLock) {
      message.error('网络请求超时，请稍后重试')
    }
    requestLock = true
    // eslint-disable-next-line
    return Promise.reject(0)
  }
)

export default axios
