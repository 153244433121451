// 公共方法
// 提示ant-form表单验证错误
import {
  notification
} from 'ant-design-vue'
import {
  showFile
} from '../request/url'

export let openUserAgreement = () => {
  window.open(showFile + '/20220211_a5ad77e0-350f-4fe1-ae03-d8ad38f57d13-5')
}
export let openSettlementAgreement = () => {
  window.open(showFile + '/20200526_9bc37d02-cfff-4c6c-8551-13fb7f7e5a03-5')
}

export let notificationErr = (obj, order) => {
  for (let i = 0; i < order.length; i++) {
    if (obj[order[i]]) {
      notification.error({
        message: '提示',
        duration: 2,
        description: obj[order[i]].errors[0].message
      })
      return
    }
  }
}
export let errorMeg = string => {
  notification.error({
    message: '提示',
    duration: 2,
    description: string
  })
}

// 金钱转换成3位一逗号(千,百万,十亿)显示
export let formatMoney = (money, type) => {
  if (money == null || money === 'null' || money === '') {
    return '0.00'
  }
  money = money.toString().replace(/^(\d*)$/, '$1.')
  money = (money + '00').replace(/(\d*\.\d\d)\d*/, '$1')
  money = money.replace('.', ',')
  var re = /(\d)(\d{3},)/
  while (re.test(money)) money = money.replace(re, '$1,$2')
  money = money.replace(/,(\d\d)$/, '.$1')
  if (type) {
    return money
  }
  return '￥' + money
}
// 次数转换成3位一逗号(千,百万,十亿)显示
export let formatFrequency = (frequency) => {
  if (frequency == null || frequency === 'null' || frequency === '') {
    return '0'
  }
  frequency = frequency.toString().replace(/^(\d*)$/, '$1.')
  frequency = (frequency + '00').replace(/(\d*\.\d\d)\d*/, '$1')
  frequency = frequency.replace('.', ',')
  var re = /(\d)(\d{3},)/
  while (re.test(frequency)) frequency = frequency.replace(re, '$1,$2')
  frequency = frequency.replace(/,(\d\d)$/, '.$1')
  let a = frequency.split('.')
  frequency = a[0]
  return frequency
}
// 判断文件是否超过5M
export let isOverFileLimitSize = (fileSize, limitFileSize) => {
  let limitSize = 5
  if (limitFileSize) {
    limitSize = limitFileSize
  }
  var agent = navigator.userAgent.toLowerCase()
  var isMac = /macintosh|mac os x/i.test(navigator.userAgent)
  if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
    if ((fileSize / Math.pow(1024, 2)).toFixed(2) > limitSize) {
      return false
    }
    return true
  }
  if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
    if ((fileSize / Math.pow(1024, 2)).toFixed(2) > limitSize) {
      return false
    }
    return true
  }
  if (isMac) {
    if ((fileSize / Math.pow(1000, 2)).toFixed(2) > limitSize) {
      return false
    }
    return true
  }
}
/**
 * @param {*} timestamp 时间戳
 * @returns 返回年月日时分秒
 */
export let timestampToTime = (timestamp) => {
  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const H = date.getHours()
  const M = date.getMinutes()
  const S = date.getSeconds() // 获取当前秒数(0-59)
  return {
    year,
    month: month < 10 ? `0${month}` : month,
    day: day < 10 ? `0${day}` : day,
    H: H === 0 ? '00' : H,
    M: M === 0 ? '00' : M,
    S
  }
}
