/**
 * type类型判断工具类
 * @authors jp
 * @date    2018-02-02
 */

export let isInvalid = obj => {
  if (typeof obj === 'string') {
    let test = obj.trim()
    switch (test) {
      case '':
        return true
      case 'false':
        return true
      case 'NaN':
        return true
    }
  }
  if (typeof obj === 'number') {
    if (obj === 0) {
      return true
    }
  }
  if (typeof obj === 'object') {
    if (obj === null) {
      return true
    }
    if (obj && obj.length === 0) {
      return true
    }
  }
  if (obj === undefined) {
    return true
  }
  return false
}

export let isValid = obj => !isInvalid(obj)
