import axios from './axios'

export default {
  // 订单列表
  orderPage (params) {
    return axios.post('api/order/orderPage', params)
  },
  // 购买订单
  createdOrder (params) {
    return axios.post('api/order/createdOrder', params)
  },
  // 支付
  pay (params) {
    return axios.post('api/order/pay', params)
  },
  // 判断优惠码
  getDiscountCodeInfo (params) {
    return axios.post('api/order/getDiscountCodeInfo', params)
  }
}
