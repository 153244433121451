import Vue from 'vue'
import App from './App.vue'
import '@a/css/index.less'
import './plugins'
import router from './router/index'
import store from './store'
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})
router.afterEach(() => {
  let content = document.getElementById('app')
  content.scrollTop = 0
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'token') {
    var newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val)
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal)
  }
}
