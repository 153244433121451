import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import account from '../request/account'
Vue.use(Router)
const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})
router.beforeEach((to, from, next) => {
  account.addOffcialWebsitePv({ url: 'https://www.zhujitech.com/#' + to.path })
  next()
})
export default router
