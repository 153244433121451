import axios from './axios'

export default {
  // 获取修改手机号
  async getCompany (params) {
    let res = await axios.post('api/company/getCompany', params)
    return res
  },
  getTiktokCompanies (params) {
    return axios.post('api/company/getTiktokCompanies', params)
  }
  // api/company/getTiktokCompanies
}
