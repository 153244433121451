// 统一报错管理
const errorTips = {
  '/example/': {
    1: '例子报错'
  },
  'api/order/createdOrder': {
    1: '错误的商品id',
    2: '错误的advertiserCompanyId'
  },
  'api/order/pay': {
    1: '错误的订单id',
    2: '订单已过期'
  },
  'api/account/getSalt': {
    1: '账号不存在'
  },
  'api/account/getUpdatePasswordSalt': {
    1: '账号不存在'
  },
  'api/account/mobilePhoneLogin': {
    1: '未发送验证码',
    2: '验证码错误',
    3: '账号不存在',
    4: '密码错误',
    5: '没有此软件权限'
  },
  'api/account/oauthLogin': {
    1: '非法登录'
  },
  'api/account/passwordLogin': {
    1: '未发送验证码',
    2: '密码错误',
    3: '没有此软件权限'
  },
  'api/account/register': {
    1: '未发送验证码',
    2: '验证码错误',
    3: '手机号已存在'
  },
  'api/account/sendLoginCaptcha': {
    1: '请求过于频繁，请过会再发送'
  },
  'api/account/sendRegisterCaptcha': {
    1: '请求过于频繁，请过会再发送',
    2: '手机号已注册'
  },
  'api/account/sendUpdatePasswordCaptcha': {
    1: '请求过于频繁，请过会再发送'
  },
  'api/account/setPassword': {
    1: '验证码错误'
  },
  'api/auth/bindAuth': {
    1: '商品id不存在',
    2: '抖音账号不存在',
    3: '该账号已绑定',
    4: '该订单已没有绑定次数'
  },
  'api/company/getTiktokCompanies': {
    1: '抖音未授权',
    2: '授权过期'
  }
}
// 后端通用code 报错提示
const responseCodeError = {
  4000: '参数错误',
  4001: '拒绝访问',
  4003: '重复提交',
  4010: '验证码错误',
  4011: '用户未登陆',
  4012: '登陆超时',
  4013: '用户被踢下',
  4014: '用户未绑定手机号',
  4015: '账户到期',
  5000: '服务器异常',
  50000: 'API接口异常,请稍后重试',
  50020: '商品不存在',
  50022: '店铺不存在',
  1001: '请输入激活码',
  1002: '激活码已失效',
  1003: '积分不足，请进行充值',
  1005: '免费次数已用完，可充值积分继续使用'
}
export { errorTips, responseCodeError }
