// import store from '@/store'
/**
 * meta.nav枚举值
 * [
    { name: 'index', title: '首页' },
    { name: 'tech', title: '电商黑科技' },
    { name: 'search', title: '在线查询工具' },
    { name: 'deatil', title: '详情页工具' },
    { name: 'optimize', title: '标题优化' },
    { name: 'flow', title: '流量平台' },
    { name: 'service', title: '运营服务' },
    { name: 'recmd', title: '软件推荐' }
  ]
 */

export default [
  {
    path: '/',
    redirect: 'discover',
    component: () => import('@v/layout')
  },
  {
    path: '/login',
    component: () => import('@v/login'),
    meta: {
      isBgFFF: true
    }
  },
  {
    path: '/registered',
    component: () => import('@v/login/registered'),
    meta: {
      isBgFFF: false
    }
  },
  {
    path: '/common',
    name: 'common',
    redirect: 'index',
    component: () => import('@v/layout'),
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        component: () => import('@v/index/index'),
        meta: {
          role: 'index',
          isBgFFF: true,
          isMoreThen1200: true,
          withoutFooter: true,
          title: '首页',
          nav: 'index'
        }
      },
      {
        path: '/order',
        component: () => import('@v/order/index'),
        meta: {
          isBgFFF: false,
          isMoreThen1200: true,
          title: '订单列表',
          withoutFooter: true
        }
      },
      {
        path: '/authorization',
        component: () => import('@v/authorization/index'),
        meta: {
          isBgFFF: false,
          withoutFooter: true,
          isMoreThen1200: true
        }
      },
      {
        path: '/pricing',
        component: () => import('@v/static/pricing'),
        meta: {
          isBgFFF: false,
          title: '定价',
          isMoreThen1200: true,
          nav: 'pricing'
        }
      },
      {
        path: '/discover',
        component: () => import('@v/static/discover'),
        meta: {
          isBgFFF: false,
          title: '发现',
          isMoreThen1200: true,
          nav: 'discover'
        }
      },
      {
        path: '/introduce',
        component: () => import('@v/static/introduce'),
        meta: {
          isBgFFF: false,
          title: '关于我们',
          isMoreThen1200: true
        }
      },
      {
        path: '/download',
        component: () => import('@v/static/download'),
        meta: {
          isBgFFF: false,
          title: '下载',
          isMoreThen1200: true,
          nav: 'download'
        }
      },
      {
        path: '/yj',
        component: () => import('@v/static/yj'),
        meta: {
          isBgFFF: false,
          title: '预警',
          isMoreThen1200: true,
          nav: 'yj'
        }
      },
      {
        path: '/xc',
        component: () => import('@v/static/xc'),
        meta: {
          isBgFFF: false,
          title: '选词',
          isMoreThen1200: true,
          nav: 'xc'
        }
      }
    ]
  }
]
