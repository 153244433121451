/*
 * @Author: your name
 * @Date: 2021-03-25 19:29:35
 * @LastEditTime: 2021-04-27 10:37:05
 * @LastEditors: your name
 * @Description: In User Settings Edit
 */
import Vue from 'vue'
import {
  Input,
  Button,
  notification,
  Modal,
  Icon,
  Menu,
  Layout,
  LocaleProvider,
  ConfigProvider,
  Checkbox,
  Pagination,
  Form,
  Spin,
  Select,
  Radio,
  Upload,
  DatePicker,
  Cascader,
  Steps,
  Tooltip,
  InputNumber,
  Popover,
  Carousel,
  message,
  Dropdown,
  Card,
  Row,
  Col,
  Table,
  Rate,
  List,
  Divider,
  Tabs,
  Descriptions,
  Slider,
  Empty,
  FormModel,
  Badge
} from 'ant-design-vue'

import 'ant-design-vue/dist/antd.less'
[
  Button,
  Icon,
  Input,
  Menu,
  Layout,
  LocaleProvider,
  ConfigProvider,
  Checkbox,
  Pagination,
  Form,
  Spin,
  Select,
  Radio,
  Upload,
  DatePicker,
  Cascader,
  Modal,
  Steps,
  Tooltip,
  InputNumber,
  Popover,
  Carousel,
  Dropdown,
  Card,
  Row,
  Col,
  Table,
  Rate,
  List,
  Divider,
  Tabs,
  Descriptions,
  Slider,
  Empty,
  FormModel,
  Badge
].map(item => {
  Vue.use(item)
})
Vue.prototype.$notification = notification
Vue.prototype.$message = message
Vue.prototype.$modal = Modal
